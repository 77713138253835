window.gcph ||= {}

gcph.tabs =
  setup: ->
    @setup_tabs()
    @setup_tab_events()

  setup_tabs: =>
    return false if document.querySelectorAll('.tab').length == 0
    tab_wrapper = document.querySelector('#tabs')
    tab_links = document.createElement('ul')
    tab_links.classList.add('tab_links')
    tab_wrapper.prepend(tab_links)

    document.querySelectorAll('.tab').forEach (el, idx) =>
      el.dataset.index = idx
      tab_title = el.querySelector('.tab_title')
      tab_title.style.display = "none"
      tab_link_item = document.createElement('li')
      tab_link_item.dataset.index = idx
      tab_link_item.classList.add('tab_link_item')

      tab_link = document.createElement('a')
      tab_link.dataset.index = idx
      tab_link.classList.add('tab_link')
      tab_link.href = '#'
      tab_link.innerHTML = tab_title.innerText

      tab_link_item.append(tab_link)

      tab_links.append(tab_link_item)


    querystring = window.gcph.tabs.get_querystring()

    if querystring.length != 0
      document.querySelector('.tab.query').classList.add('active')
      document.querySelector(".tab_link[data-index='#{document.querySelector('.tab.query').dataset.index}']").classList.add('active')
      document.querySelector(".tab_link_item[data-index='#{document.querySelector('.tab.query').dataset.index}']").classList.add('active')
    
    else
      document.querySelector(".tab[data-index='0']").classList.add('active')
      document.querySelector(".tab_link[data-index='0']").classList.add('active')
      document.querySelector(".tab_link_item[data-index='0']").classList.add('active')

  get_querystring: (query) =>
    reg = new RegExp( '[?&]q=([^&#]*)', 'i' )
    string = reg.exec(window.location.href)
    if string
      string[1]
    else
      ''

  setup_tab_events: ->
    document.querySelectorAll('.tab_link').forEach (tl) =>
      tl.addEventListener 'click', (e) =>
        e.preventDefault()

        document.querySelectorAll('.tab, .tab_link, .tab_link_item').forEach (el) =>
          el.classList.remove('active')

          if el.dataset.index == tl.dataset.index
            el.classList.add('active')
        
        e.target.blur()