window.gcph ||= {}

gcph.event_booking =
  setup: ->
    return if document.querySelectorAll('.nested_model_fields').length <= 0
    @add_listeners()
    @update_count()

  add_listeners: ->
    document.querySelector('.buttons a').addEventListener 'click', @create_nested_field
    remove_links = document.querySelectorAll('.nested_model_item a.remove')
    if remove_links
      remove_links.forEach (link) =>
        link.addEventListener 'click', @remove_nested_field

  create_nested_field: (e) ->
    e.preventDefault()

    nestedItems = document.querySelectorAll('.nested_model_item')
    count = e.target.dataset.count
    return if  nestedItems.length >= count

    template = document.querySelector('template')
    timestamp = new Date().getTime().toString()
    content = template.innerHTML.replace(/NEW_RECORD/g, timestamp)
    document.querySelector('.nested_model_fields').insertAdjacentHTML('beforeend', content)

    gcph.event_booking.add_listeners()
    gcph.event_booking.update_count()

  remove_nested_field: (e) ->
    e.preventDefault()

    wrapper = e.target.closest('.nested_model_item')
    if (wrapper.dataset.newRecord == 'true')
      wrapper.remove()
    else
      wrapper.style.display = 'none'
      input = wrapper.querySelector('input[name*="_destroy"]')
      input.value = '1'
      input.removeAttribute('disabled')

    gcph.event_booking.update_count()

  update_count: ->
    nestedItems = document.querySelectorAll('.nested_model_item')
    return if nestedItems.length <= 0

    nestedItems.forEach (item, idx) =>
      item.querySelector('.attendee_count').innerHTML = idx + 1

    button = document.querySelector('.buttons a')
    count = button.dataset.count
    if nestedItems.length >= count
      button.disabled = true
      button.classList.add('disabled')
    else
      button.disabled = false
      button.classList.remove('disabled')
