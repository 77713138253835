window.gcph ||= {}

gcph.statistic =
  setup: ->
    @add_listeners()

  add_listeners: ->
    return false unless document.querySelectorAll('.statistic_content_block').length > 0

    document.querySelectorAll('.statistic_content_block').forEach (block) ->
      # Getters
      progress_bar = block.querySelector('circle')

      to = parseInt(block.getAttribute('data-to'))
      sdo = parseFloat(progress_bar.getAttribute('stroke-dashoffset'))

      # Setters
      to_sdo = sdo - ((to / 100) * sdo);

      # Set initial progress
      progress_bar.style.strokeDashoffset = "#{to_sdo}"