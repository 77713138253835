window.gcph ||= {}

gcph.maps =
  mapElements: document.querySelectorAll('.google_map')
  apiKey: null

  setup: ->
    return false unless gcph.maps.mapElements.length

    gcph.maps.apiKey = gcph.maps.getApiKey()
    gcph.maps.loadGoogleMapsApi()

  getApiKey: ->
    apiKeyElement = document.querySelector('.google_map[data-key]')
    return apiKeyElement?.getAttribute('data-key')

  loadGoogleMapsApi: ->
    scriptId = 'gmap_loader'
    if !document.getElementById(scriptId)
      script = document.createElement('script')
      script.id = scriptId
      script.src = "https://maps.googleapis.com/maps/api/js?key=#{gcph.maps.apiKey}&callback=gcph.maps.initialize"
      document.getElementsByTagName('script')[0].parentNode.insertBefore(script, document.getElementsByTagName('script')[0])
    else
      gcph.maps.initialize()

  initialize: ->
    @setup_maps()

  setup_maps: ->
    gcph.maps.mapElements.forEach (location) ->
      map = gcph.maps.create_map(location)
      icon = location.getAttribute('data-icon')
      marker = new google.maps.Marker({
        position: { lat: parseFloat(location.getAttribute('data-lat')), lng: parseFloat(location.getAttribute('data-lng')) },
        icon: icon,
        map: map
      })

  create_map: (location) ->
    options =
      center: new google.maps.LatLng(location.getAttribute('data-lat'), location.getAttribute('data-lng'))
      mapTypeId: google.maps.MapTypeId.ROADMAP
      maxZoom: 18
      zoom: 17
      zoomControl: true
      mapTypeControl: false
      streetViewControl: false
      fullscreenControl: false

    new google.maps.Map(location, options)
