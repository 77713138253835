window.gcph ||= {}

gcph.home =

  setup: ->
    @setup_home_banner()
    
  setup_home_banner: ->
    home_banner_content = document.querySelector('.home_banner_content')
    return unless home_banner_content

    if window.matchMedia('(min-width: 850px)').matches
      home_banner_content.style.marginTop = "-#{home_banner_content.offsetHeight / 2}px"
