import './vendor/gsap.min.js'
import './tictoc/environment.js.erb'
import './tictoc/preview.js'
import './tictoc/ui.js'
import './gcph/custom_select.js.coffee'
import './gcph/article_filter.js.coffee'
import './gcph/menu.js.coffee'
import './gcph/statistic.js.coffee'
import './gcph/video.js.coffee'
import './gcph/tabs.js.coffee'
import './gcph/map.js.coffee'
import './gcph/event_booking.js.coffee'
import './gcph/home.js.coffee'
import './gcph/cookie_notice.js.coffee'

var ready;

ready = function (callback) {
  if (document.readyState !== 'loading') {
    callback();
  } else if (document.addEventListener) {
    document.addEventListener('DOMContentLoaded', callback);
  } else {
    document.attachEvent('onreadystatechange', function () {
      if (document.readyState === 'complete') {
        callback();
      }
    });
  }
};

ready(function () {
  tictoc.preview.setup();
  tictoc.ui.setup();
  gcph.menu.setup();
  gcph.custom_select.setup();
  gcph.article_filter.setup();
  gcph.statistic.setup();
  gcph.video.setup();
  gcph.tabs.setup();
  gcph.maps.setup();
  gcph.event_booking.setup();
  gcph.home.setup();
  gcph.cookie_notice.setup();
});